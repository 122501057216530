import React, { useState } from 'react';
import './App.css';
import axios from 'axios';

function App() {
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageUpload = event => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setSelectedImages(files);
    }
  };

  const handleSendClick = async () => {
    if (selectedImages.length > 0) {
      const results = await Promise.all(selectedImages.map(imageFile => analyzeImage(imageFile)));
      console.log(results); // Handle the results as needed
    } else {
      alert("Please upload images first.");
    }
  };

  const analyzeImage = async (imageFile) => {
    const formData = new FormData();
    formData.append("size", "640");
    formData.append("confidence", "0.25");
    formData.append("iou", "0.45");
    formData.append("image", imageFile);

    const config = {
      method: 'post',
      url: 'https://api.ultralytics.com/v1/predict/MQLrxhjyv5ubkw3MfOkW',
      headers: { 
        'x-api-key': 'your_api_key_here', 
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <input type="file" multiple onChange={handleImageUpload} accept="image/*" />
        <button onClick={handleSendClick}>Send</button>
      </header>
    </div>
  );
}

export default App;
